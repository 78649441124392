import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js??ref--12-1!../../node_modules/@angular-devkit/build-angular/node_modules/postcss-loader/dist/cjs.js??ref--12-2!../../node_modules/postcss-loader/dist/cjs.js??ref--17!./styles.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};